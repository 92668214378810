import React from 'react';
import { useNavigate } from 'react-router-dom';  
import styles from '../css/login.module.css';
import Button from '../components/Button';

const Login = () => {
  const navigate = useNavigate();  

  const handleLogin = (event) => {
    event.preventDefault(); // Evita el comportamiento por defecto del formulario
    // Aquí podrías agregar la lógica para autenticar al usuario
    // Después de autenticar, redirige a la página de comanda
    navigate('/comanda');  
  };

  return (
    <div className={styles.divBody}>
        <div className={styles.loginContainer}>
        <h2>Inicio de Sesión</h2>
        <form onSubmit={handleLogin}>  
            <div className={styles.formGroup}>
            <input type="text" placeholder="Usuario" required />
            </div>
            <div className={styles.formGroup}>
            <input type="password" placeholder="Contraseña" required />
            </div>
            <Button label="Ingresar" variant="default" size="large"/> 
        </form>
        </div>
    </div>
  );
};

export default Login;
