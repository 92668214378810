// src/components/LoginButton.js
import React from 'react';
import styles from '../css/button.module.css';

const LoginButton = ({ label, variant = 'default', size = 'medium', onClick, disabled = false }) => {
  const buttonClass = `${styles.Boton} ${styles[variant]} ${styles[size]} ${disabled ? styles.disable : ''}`;

  return (
    <button 
      type="submit" 
      className={buttonClass} 
      onClick={onClick} 
      disabled={disabled} // Desactiva el botón
    >
      {label}
    </button>
  );
};

export default LoginButton;
