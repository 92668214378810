import React from 'react';
import styles from '../css/loader.module.css'; // Asegúrate de crear este archivo de estilos

const Loader = () => {
    return (
        <div className={styles.loaderContainer}>
            <div className={styles.loader}></div>
            <p className={styles.font}>Cargando Ordenes...</p>
        </div>
    );
};

export default Loader;
