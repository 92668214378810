import Login from './pages/Login';
import Comanda from './pages/Comanda';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
 

function App() {
  return (
 
      <Router>
        <div className="App">
          {/* Definición de las rutas */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/comanda" element={<Comanda />} />
          </Routes>
        </div>
      </Router>
 
  );
}

export default App;
