import React, { useState, useEffect } from 'react';
import styles from '../css/orders.module.css';
import Button from '../components/Button';
import { actualizaEstadoOrden } from '../services/authService';

const OrderCard = ({ order }) => {
    const { 
        orderId, 
        orderStatus, 
        items, 
        comments,
        orderTimestamp,
        tiempoTranscurrido,
    } = order;
  
    const [isPreparing, setIsPreparing] = useState(orderStatus === "Preparando");
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [currentOrderStatus, setCurrentOrderStatus] = useState(orderStatus);

    useEffect(() => {
        let timer;

        if (isPreparing) {
            timer = setInterval(() => {
                setTimeElapsed(prevTime => prevTime + 1);
            }, 1000);
        }

        return () => clearInterval(timer); // Detener el temporizador cuando el componente se desmonte o cambie el estado
    }, [isPreparing]);

    const handlePrepareClick = async () => {
        console.log("Botón Preparar presionado");
        setIsPreparing(true); // Inicia el temporizador
        setTimeElapsed(0);

        try {
            const nuevoEstado = "Preparando";
            const time = 0
            // Llamada a la API para actualizar el estado
            const response = await actualizaEstadoOrden(orderId, nuevoEstado, time);
            if (response) {
                setCurrentOrderStatus(nuevoEstado);
                console.log("Estado de la orden actualizado a Preparando");
            } else {
                console.error("Error al actualizar el estado de la orden:", response?.message || "Desconocido");
            }
        } catch (error) {
            console.error("Error al actualizar el estado de la orden:", error);
        }
    };

    const handleReadyClick = async () => {
        console.log("Botón Listo para recoger presionado");
    
        try {
            const nuevoEstado = "Listo";
            const time = formatTime(timeElapsed); // Usa el tiempo transcurrido actual
            // Llamada a la API para actualizar el estado
            const response = await actualizaEstadoOrden(orderId, nuevoEstado, time);
            if (response) {
                setCurrentOrderStatus(nuevoEstado);
                setIsPreparing(false); // Detener el temporizador sin reiniciar el tiempo
                console.log("Estado de la orden actualizado a Listo para recoger");
            } else {
                console.error("Error al actualizar el estado de la orden:", response?.message || "Desconocido");
            }
        } catch (error) {
            console.error("Error al actualizar el estado de la orden:", error);
        }
    };
    

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <div 
            className={`${styles.comanda} ${
            currentOrderStatus === "Pendiente"
              ? styles.comandaColorEsperando
              : currentOrderStatus === "Preparando"
              ? styles.comandaColorPreparando
              : currentOrderStatus === "Listo"
              ? styles.comandaColorListo
              : ""
            }`}
        >
            <div className={styles.sectionTitle}>Pedido # {orderId}</div>
            <div className={styles.item}>
                <ul>
                {items.map((item, index) => (
                    <li key={`${item.id}-${index}`}>{item.quantity} x {item.nombrePlatillo}</li>
                ))}
                </ul>
            </div>
            <div className={styles.instructions}>Instrucciones: {comments || 'Ninguna'}</div>
            <div className={styles.sectionTitle}>Hora del pedido: {new Date(orderTimestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
            <div className={styles.sectionTitle}>Tiempo estimado: 12:00</div>
            <div className={styles.sectionTitle}>
                Tiempo transcurrido: { tiempoTranscurrido || formatTime(timeElapsed)}
            </div>
            
            <div className={styles.sectionTitle}>
                Estado: 
                <label
                    className={
                        currentOrderStatus === "Pendiente" ? styles.statusPendiente :
                        currentOrderStatus === "Preparando" ? styles.statusPreparando :
                        currentOrderStatus === "Listo" ? styles.statusListo :
                        ""
                    }
                    >
                    {currentOrderStatus}
                </label>
            </div>
            <div className={styles.actions}>
                <Button label="Listo para recoger" variant="ready" size="small" onClick={handleReadyClick}  disabled={currentOrderStatus === "Pendiente"}/> 
                <Button label="Preparar" variant="preparing" size="small" onClick={handlePrepareClick} /> 
            </div>
        </div>
    );
};

export default OrderCard;
